import React from 'react';
import './backgroundvideo.scss';

export default function BackgroundVideo() {
    return(
        <header className="app-header">
            <div>
                <h1>Pixel Perfect Detailing</h1>
                <p>Fort Wayne, Indiana - Automotive Detailer</p>
                <video loop autoPlay muted id="bg-video">
                    <source src={require('..//assets/banner.mp4')} type="video/mp4" />
                </video>
            </div>
        </header>
    )
}
