import React from 'react';
import './threecolumn.scss';

export default function ThreeColumn() {
    return(
        <div className='threecolumn'>
            <div className='column'>
                <a href='/new-car' className='box'>
                    <img src='./assets/new.jpg' alt='New Car Protection - Tesla in the Leaves' />
                    <h2>New Car Protection</h2>
                    <p>Protecting your new investment to ensure it looks new as long as possible.</p>
                    <p className='column-button'>Schedule Today</p>
                </a>
                </div>
                <div className='column'>
                <a href='/headlight-restoration' className='box'>
                    <img src='./assets/headlight.jpg' alt='Headlight Restoration - A Restored Headlight' />
                    <h2>Headlight Restoration</h2>
                    <p>Have foggy or yellow headlights? We can fix them and keep them looking new for years to come</p>
                    <p className='column-button'>Schedule Headlight Restoration</p>
                </a>
                </div>
                <div className='column'>
                <a href='/car-detailing' className='box'>
                    <img src='./assets/wash.jpg' alt='Car Detailing - A car begin washed' />
                    <h2>Car Detailing</h2>
                    <p>We offer services to ensure your car can stay looking its best all year round.</p>
                    <p className='column-button'>Schedule a Cleaning</p>
                </a>
                </div>

            </div>
    )
}
