import React from 'react';

import BackgroundVideo from './Components/backgroundvideo'; 
import ThreeColumn from './Components/threecolumn';
import Contact from './Components/contact';

  

function Home() {


  return (
    <div className="App">
      <BackgroundVideo></BackgroundVideo>

      <div className='main-container'>
        <div>
          <ThreeColumn></ThreeColumn>
        </div>

        <div className='intro'>
          <h2>Fort Wayne Automotive Detailing</h2>
          <p>Does your new car still turn heads, or has everyday wear and tear started to dim its shine? At Pixel Perfect Detailing in Fort Wayne, Indiana, we specialize in keeping your new car looking showroom-fresh and restoring older vehicles to their former glory. </p>
          <p>Our expert detailers use only the finest polishes and waxes, meticulously removing imperfections and bringing back that deep, glossy shine. We also offer top-of-the-line ceramic coatings, providing superior protection against the elements and preserving your investment for years to come. </p>
          <p>Let Pixel Perfect Detailing help you keep your car looking its absolute best.</p>
        </div>


      </div>

       <Contact />

      <footer>
      <div className='address'>
          <address>
            Pixel Perfect Detailing<br />
            Fort Wayne, IN 46804<br />
            <a href="tel:+12602094530">(260) 209-4530</a>
          </address>
        </div>
        <div className='copyright'>
          &copy; 2024 Pixel Perfect Detailing 
        </div>
      </footer>
    </div>
  );
};
  
export default Home;