import React from 'react';
import BackgroundVideo from './Components/backgroundvideo'; 
import Contact from './Components/contact';
  

function HeadlightRestoration() {

  return (
    <div className="App">
      <BackgroundVideo></BackgroundVideo>

      <div className='main-container'>


        <div className='intro'>
          <h2>Headlight Restoration</h2>
          <p>Overtime, all modern plastic headlights will fade and yellow, we can help restore them.</p>
          <p>Our process starts with wet sanding to ensure all the effected plastic is removed. Then polishing them to ensure they are perfectly clear. To ensure they stay looking this way as long as possible we then add a clear coat and a ceramic coating.</p>
          <p>We don't claim that your headlights won't fade again, but with the proper maintenance and care, they will look new for years to come. Removing the oxidized layer is the first step.</p>
          <p>We offer this as a mobile service, and typically takes less than 90 minutes to complete. Prices for headlight restoration starts at $75.</p>
        </div>

        <div className='photo-list'>
          <img src="./assets/headlight.jpg" alt="A clean and clear headlight" />
          <img src="./assets/headlight-1.jpg" alt="Before and after headlight restoration" />
          <img src="./assets/headlight-2.jpg" alt="Lexus headlight restoration" />

        </div>

      </div>
       <Contact />
      <footer>
      <div className='address'>
          <address>
            Pixel Perfect Detailing<br />
            Fort Wayne, IN 46804<br />
            <a href="tel:+12602094530">(260) 209-4530</a>
          </address>
        </div>
        <div className='copyright'>
          &copy; 2024 Pixel Perfect Detailing 
        </div>
      </footer>
    </div>
  );
};
  
export default HeadlightRestoration;