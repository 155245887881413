import React from 'react';
import BackgroundVideo from './Components/backgroundvideo'; 
import Contact from './Components/contact';
  

function NewCar() {

  return (
    <div className="App">
      <BackgroundVideo></BackgroundVideo>

      <div className='main-container'>


        <div className='intro'>
          <h2>New Car Protection</h2>
          <p>A car is the 2nd biggest purchase you may ever make in your life. Don't trust the dealerships to add their overpriced, short-term coatings to your vehicle. </p>
          <p>Letting us protect your car's paint before the first wash could prolong your paint and keep that showroom shine for much longer.</p>
          <p>We offer multiple levels of protection, we can give you a much better quote after a short conversation.</p>
        </div>




      </div>
       <Contact />
      <footer>
      <div className='address'>
          <address>
            Pixel Perfect Detailing<br />
            Fort Wayne, IN 46804<br />
            <a href="tel:+12602094530">(260) 209-4530</a>
          </address>
        </div>
        <div className='copyright'>
          &copy; 2024 Pixel Perfect Detailing 
        </div>
      </footer>
    </div>
  );
};
  
export default NewCar;