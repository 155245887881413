import React from 'react';
import BackgroundVideo from './Components/backgroundvideo'; 
import Services from './Components/services';
import Contact from './Components/contact';

  

function CarDetailing() {

  return (
    <div className="App">
      <BackgroundVideo></BackgroundVideo>

      <div className='main-container'>

        <div className='intro'>
          <h2>Car Maintenance</h2>
          <p>Keeping your car clean is the best thing you can do for your the life of your paint. Here in the midwest the winter salt can eat through your car quickly.</p>
          <p>At Pixel Perfect Detailing we offer to help you with this by offering maintenance washes for your vehicle.    
          </p>
          <p>
            After an initial exterior detail, we offer a maintenance wash for $40.
            <br/>
            This includes:
            <ul>
              <li>
                Through exterior wash
              </li>
              <li>
                Wheels &amp; rims cleaned
              </li>
              <li>
                Fender Liners cleaned
              </li>
              <li>
                Door Jambs cleaned
              </li>
              <li>
                Dried with detailing spray and soft towels
              </li>
            </ul>
          </p>
        </div>

        <Services />

        <div className='photo-list'>
          <img src="./assets/exterior-detail-1.jpg" alt="A clean, freshly detailed vehicle" />
          <img src="./assets/interior-detail-1.jpg" alt="A clean, detailed interior" />
          <img src="./assets/bike-detail.jpg" alt="e-bike with ceramic coating" />

        </div>
       

      </div>
        <Contact /> 
      <footer>
        <div className='address'>
          <address>
            Pixel Perfect Detailing<br />
            Fort Wayne, IN 46804<br />
            <a href="tel:+12602094530">(260) 209-4530</a>
          </address>
        </div>
        <div className='copyright'>
          &copy; 2024 Pixel Perfect Detailing 
        </div>
      </footer>
    </div>
  );
};
  
export default CarDetailing;