import React from 'react';
import './services.scss';

export default function Services() {
    return(


<div className='services'>
          <h2>Detailing Packages</h2>

          <div className='detail-packages'>
          <div className='detail-option'>
              <h3>Exterior Detail <span>Starting at $125</span></h3>
              <div className='detail-content'>
                An exterior detail includes
                <ul>
                    <li>
                        A full exterior wash (body, tires, fenders, truck bed, underbody)
                    </li>
                    <li>
                        Chemical decontamination
                    </li>
                    <li>
                        Clay bar
                    </li>
                    <li>
                        ~6 month paint protection
                    </li>
                    <li>
                        Tire shine
                    </li>
                    <li>
                        All glass cleaned
                    </li>

                </ul>
              </div>
              </div>
              <div className='detail-option'>
              <h3>Interior Detail <span>Starting at $150</span></h3>
              <div className='detail-content'>
                An interior detail includes
                <ul>
                    <li>
                         Debris removal and vaccum of all carpeted surfaces
                    </li>
                    <li>
                        All hard surfaces cleaned and protected
                    </li>
                    <li>
                        Interior windows cleaned
                    </li>
                    <li>
                        Door jambs cleaned
                    </li>
                    <li>
                        Leather Seats cleaned, cloth seats swept and surface dirt removed
                    </li>
                    <li>
                        Floor mats cleaned and protected
                    </li>

                </ul>
              </div>
              </div>
              <div className='detail-option'>
              <h3>Ceramic Coatings<span>Pricing varies</span></h3>
              <div className='detail-content'>
                We have a variety of ceramic coating options
                <ul>
                    <li>
                         Exterior paint coated with a single layer
                    </li>
                    <li>
                        Additional hydrophobic layers to prolong the base ceramic
                    </li>
                    <li>
                        Window/windshield coatings
                    </li>
                    <li>
                        Interior cloth coatings
                    </li>
                    <li>
                        Leather seat coatings
                    </li>
                    <li>
                        Bicycle, marine, other outdoor machine coatings
                    </li>

                </ul>
              </div>
            </div>
            </div>
            <div className='other-services'>
            <h3>
                Other Services
            </h3>
            <p>We have other add-ons services as needed and requested.  </p>
                <ul>
                    <li>Pet hair removal</li>
                    <li>Deep carpet cleaning</li>
                    <li>Engine bay detail</li>
                    <li>Leather conditioner</li>
                    <li>Paint Polishing/Correcting</li>
                    <li>Headliner Cleaning</li>
                </ul>
              <p>Prices vary for these services, please contact us for a quote</p>
          </div>
        </div>

)
}
