import React from 'react';
import './App.scss'
import {Routes, Route } from 'react-router-dom';
import Home from './home';
import HeadlightRestoration from './headlight-restoration';
import NewCar from './newcar';
import CarDetailing from './car-detailing';

function App() {
  return (

  <Routes>
    <Route path='/' element={<Home />} />
    <Route path='/new-car' element={<NewCar />} />
    <Route path='/headlight-restoration' element={<HeadlightRestoration />} />
    <Route path='/car-detailing' element={<CarDetailing />} />
  </Routes>
  )
}

export default App;
